exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CMJ4zM7AsIAMOSfOSMahL {\n    margin: 30px auto 10px auto;\n}\n._3EqMcG5xTMfW-XnKFjqOGF {\n    margin: 0 auto 30px auto;\n    text-align: center;\n}\n._2eihLWDIoE9ocYFEPYecUj {\n    margin: 30px auto 50px auto;\n    color: #999;\n}", ""]);

// exports
exports.locals = {
	"cardHeader": "CMJ4zM7AsIAMOSfOSMahL",
	"cardTitle": "_3EqMcG5xTMfW-XnKFjqOGF",
	"cardBody": "_2eihLWDIoE9ocYFEPYecUj"
};