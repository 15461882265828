exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1lJ9X8Eirh-vR1QHM1bLEI {\n    border-radius: 8pt;\n    box-shadow: 5px 5px 5px rgba(100, 100, 100, .5);\n    height: 120px;\n}\n\n.hQ7stXFdfd_4Qcg-sMzCa {\n    font-size: 18px;\n}\n\n._2aL3d1ajpza4ZlppZeB1y6 {\n    color: red;\n}\n\n._2lZ5ByGE2mVa4PqSI3uVVO {\n    display: none;\n}\n\n._6Y_CQPHNSijHUoa1fUAST {\n    display: block;\n}", ""]);

// exports
exports.locals = {
	"iconImg": "_1lJ9X8Eirh-vR1QHM1bLEI",
	"formLable": "hQ7stXFdfd_4Qcg-sMzCa",
	"required": "_2aL3d1ajpza4ZlppZeB1y6",
	"hideMsg": "_2lZ5ByGE2mVa4PqSI3uVVO",
	"showMsg": "_6Y_CQPHNSijHUoa1fUAST"
};