exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1mVaElP-lnznWgLz5r09TJ,\n._21jei8Zm3J7Wu33d-Vda9N {\n    margin-top: 0;\n}\n\n.A2jWBlD7ZoBjTgbAQhmAj {\n    font-size: 9pt;\n    color: #999;\n}\n\n._2Am6sjm1SwQCyw-1pLsaaA {\n    border-radius: 8pt;\n    box-shadow: 5px 5px 5px rgba(100, 100, 100, 0.5);\n    height: 120px;\n}\n\n.cCZefXQC972o6OnkAArPh {\n    color: #ff0000;\n}\n", ""]);

// exports
exports.locals = {
	"news_title": "_1mVaElP-lnznWgLz5r09TJ",
	"news_paragraph": "_21jei8Zm3J7Wu33d-Vda9N",
	"news_last_update": "A2jWBlD7ZoBjTgbAQhmAj",
	"iconImg": "_2Am6sjm1SwQCyw-1pLsaaA",
	"redText": "cCZefXQC972o6OnkAArPh"
};